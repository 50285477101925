/* eslint-disable no-nested-ternary */
import React, { FC, useState, useEffect, useRef, useCallback } from 'react';
import { connect, useSelector } from 'react-redux';
import { withRouter, useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import moment from 'moment';
import { setSpin, setTitleModalLogin, showModalLogin } from 'actions/common';
import { fetchRole } from 'actions/role';
import { setBookingId, setBookingPhone } from 'actions/booking';
import { fetchAllSalon } from 'actions/salon';
import { notification, Button } from 'antd';
import {
    setQuery,
    getQuery,
    diffTimeToday,
    formatPriceServiceToNum,
} from 'utils/BookingUltils';
import { validatePhone } from 'utils/validator';
import MySpin from 'components/MySpin/MySpiner';
import API from 'repository';
import trackingConst from 'config/TrackingConst';
import usePrevious from 'hooks/usePrevious';
import KEY from './Const';
import TitleScreen from './components/TitleScreen';
import SalonNewMessage from './components/SalonNewMessage';
import ButtonBottom from './components/ButtonMainScreen';
import { IComboDetail, IListService, IService } from './types/Service';
import { IExtension } from './types/Extention';
import {
    AccessLocationType,
    IParams,
    ISelected,
    ISelectedSalonBackUp,
} from './types/Selected';
import { IDataModalSalonOff, ISalon } from './types/Salon';
import './styles.scss';
import MainScreen from './MainScreen';
import SalonScreen from './SalonScreen';
import ServiceScreen from './ServiceScreen';
import ModalBooking from './components/ModalBooking';
import ModalSalonOff from './components/ModalSalonOff';
import ModalOtp from './components/ModalOtp';
import { IStylistSelected } from './types/Stylist';
import CampaignScreen, { setChosenServiceWithCampaign } from './CampaignScreen';
import { fetchCampaign } from './CampaignScreen/ListCampaignScreen';

const TODAY = moment(new Date()).format('DD-MM-YYYY');

const dfHour = {
    bookHourId: 0,
    subHourId: 0,
    secondSubHourId: 0,
    hour: '',
    hourFrame: '',
};

const initialSelectedSalonBackUp = {
    [KEY.SALON]: {
        id: 0,
        name: '',
        openingDate: '',
        warnText: '',
        addressNew: '',
        address: '',
        bookManyPeople: '',
    },
    [KEY.HOUR]: dfHour,
};

const Booking: FC<{
    fetchRole: any;
    setSpin: Function;
    setBookingSuccess: Function;
    trackingData: any;
    userPhone: any;
    userId: any;
    inApp: boolean;
    role: any;
    userData: any;
    setCustomerPhone: Function;
    showModalLogin: Boolean;
    setShowModalLogin: Function;
    setTitleModalLogin: Function;
    lang: string;
    listSalon: ISalon[];
}> = ({
    fetchRole,
    setSpin,
    setBookingSuccess,
    trackingData,
    userPhone,
    userId,
    inApp,
    role,
    userData,
    setCustomerPhone,
    showModalLogin,
    setShowModalLogin,
    setTitleModalLogin,
    lang,
    listSalon,
}) => {
    const history = useHistory();
    const location = useLocation();
    const isFirstRender = useRef<boolean>(true);
    const getDataquery = (string: string) => {
        const data: any = getQuery(string);
        return data;
    };

    const getResult = useCallback(() => getDataquery(history.location.search), [
        history.location.search,
    ]);
    const appSources = [
        'customer_ios',
        'customer_android',
        'friendly_checkin',
        'app_customer',
    ];
    const result: any = useRef<IParams>(getResult());
    const bookingId = useRef<number>(0);

    const [isBookStylistMaster, setIsBookStylistMaster] = useState(false);
    const [ruleShowBooking, setRuleShowBooking] = useState<Array<any>>([]);
    const [selected, setSelected] = useState<ISelected>({
        [KEY.PHONE]: result.current.phone || '',
        [KEY.DATE_BOOK]: TODAY,
        [KEY.STYLIST]: { id: 0, name: '' },
        [KEY.SALON]: {
            id: result.current.salonId || 0,
            name: '',
            openingDate: '',
            warnText: '',
            addressNew: '',
            address: '',
            bookManyPeople: '',
        },
        [KEY.NOS]: true,
        [KEY.CHOSEN_SERVICES]: [],
        [KEY.CHOSEN_SERVICES_WEEKEND]: [],
        [KEY.HOUR]: dfHour,
        [KEY.STEP]: result.current[KEY.STEP] || 0,
        [KEY.CAMPAIGN]: undefined,
        [KEY.AVAILABLE_HOURS]: 0,
        [KEY.NOTE]: '',
        [KEY.NOTE_STYLIST_MOVE_SALON]: '',
        [KEY.LIST_SERVICES]: {},
        [KEY.RECEIVE_ADVICE]: true,
        [KEY.UTILITY_SERVICE]: [],
        [KEY.ACCEPT_MAKE_PHOTO]: true,
        [KEY.LITTLE_ADVICE]: false,
        [KEY.NUMBER_PERSONS]: 0,
        [KEY.SKINNER_ID]: parseInt(result.current.skinnerId) || 0,
        listServiceSaleInfo: [],
        [KEY.CHOSEN_CAMPAIGN]: [],
        [KEY.LIST_CAMPAIGN]: null,
        [KEY.LIST_ALL_SERVICES]: [],
        adviceUND: null,
        adviceProduct: null,
        muonCaoMat: false,
        [KEY.GROUP_BOOKING]: 1,
        [KEY.BOOK_HOURS]: [],
    });

    const [hasClickNextInService, setHasClickNextInService] = useState(1);
    const [hasClickInSalon, setHasClickNextInSalon] = useState(1);
    const [hasClickNextInTime, setHasClickNextInTime] = useState(0);
    const [isClickNextInService, setIsClickNextInService] = useState(true);
    const [allowChange, setAllowChange] = useState(false);

    const prevStep = usePrevious(selected[KEY.STEP]);

    const [selectedSalonBackUp, setSelectedSalonBackUp] = useState<
        ISelectedSalonBackUp
    >(initialSelectedSalonBackUp);
    const [isSelectedSalonNear, setIsSelectedSalonNear] = useState<
        AccessLocationType
    >({
        isAllowAccessLocation: false,
        isDeniedAccessLocation: false,
    });
    const [guideService, setGuideService] = useState([]);
    // modal booking SDT
    const [isShowModalBooking, setShowModalBooking] = useState(false);
    // data salon dong cua
    const [isShowModalSalonOff, setIsShowModalSalonOff] = useState<boolean>(
        false,
    );
    const [previOusChosenServices, setPreviOusChosenServices] = useState<
        IService[]
    >([]);
    const [dataModalSalonOff, setDataModalSalonOff] = useState<
        IDataModalSalonOff
    >({ message: '', salonBackups: [], salonId: 0, screenId: '' });
    const [isLoadingServices, setIsLoadingServices] = useState<boolean>(false);
    const [visibleOtp, setVisibleOtp] = useState(false);
    const [bookingData, setBookingData] = useState(null);
    const refSalonScreen = useRef<any>(null);
    const common = useSelector((state: any) => state.common);
    const [listCampaignTags, setListCampaignTags] = useState<
        CampaignServiceTagType[]
    >([]);
    const [
        campaignDetailSelected,
        setCampaignDetailSelected,
    ] = useState<CampaignType | null>(null);
    const { step } = selected;

    const onChangeSelected = (key: string, value: any) => {
        setSelected((state) => ({ ...state, [key]: value }));
        if (key === KEY.HOUR) {
            setHasClickNextInTime(0);
        }
    };
    const onChangeSelectedBackUp = (key: string, value: any) => {
        setSelectedSalonBackUp((state) => ({ ...state, [key]: value }));
    };

    const resetSelectedBackUp = () => {
        setSelectedSalonBackUp(initialSelectedSalonBackUp);
    };

    const onChangeSalon = (value: ISalon, step = 0) => {
        const newSelectedSalon: ISalon = { ...value };
        let dateBook = selected[KEY.DATE_BOOK];
        const check = diffTimeToday(value.openingDate);
        const checkDiffTimeOldSalon = diffTimeToday(
            selected[KEY.SALON].openingDate,
        );

        if (checkDiffTimeOldSalon) {
            dateBook = TODAY;
        }
        if (check) {
            dateBook = value.openingDate;
        } else {
            newSelectedSalon.openingDate = TODAY;
        }
        setSelected((state) => ({
            ...state,
            [KEY.SALON]: newSelectedSalon,
            [KEY.STEP]: step,
            [KEY.DATE_BOOK]: dateBook,
            [KEY.STYLIST]: { id: 0, name: '' },
            [KEY.NOTE]: '',
            [KEY.NOTE_STYLIST_MOVE_SALON]: '',
            [KEY.HOUR]: dfHour,
            [KEY.NUMBER_PERSONS]: 0,
            [KEY.SKINNER_ID]: 0,
            [KEY.CHOSEN_SERVICES]: step === 2 ? [] : state[KEY.CHOSEN_SERVICES],
        }));
    };

    const postTracking = async (data: any) => {
        try {
            API.postTrackingDynamic(data);
        } catch (e) {
            console.log(e.message);
        }
    };

    const handleBooking = async (key: string) => {
        setSpin(true);
        let services = '';
        let formatChosenService: {
            id: number;
            name: string;
            type: string;
            campaignId?: number;
            campaignName?: string;
        }[] = [];
        if (selected[KEY.CHOSEN_SERVICES].length > 0) {
            selected[KEY.CHOSEN_SERVICES].filter((item) => {
                const campaign = selected[KEY.CHOSEN_CAMPAIGN].find(
                    (item) => item.campaignId,
                );
                let newComboDetail = item?.comboDetail
                    ? typeof item.comboDetail === 'string'
                        ? JSON.parse(item.comboDetail)
                        : item.comboDetail
                    : [];
                newComboDetail = newComboDetail.map((item: IComboDetail) => ({
                    ...item,
                    campaignId: campaign ? campaign.campaignId : null,
                    campaignName: campaign ? campaign.name : null,
                }));
                const currentServices: IComboDetail[] = newComboDetail.length
                    ? newComboDetail
                    : [
                        {
                            id: item.serviceId,
                            name: item.name,
                            type: 'service',
                            campaignId: campaign ? campaign.campaignId : null,
                            campaignName: campaign ? campaign.name : null,
                        },
                    ];
                formatChosenService = [
                    ...formatChosenService,
                    ...currentServices,
                ];
                services += `${currentServices
                    .map((item) => item.name)
                    .join(',')},`;
                return services;
            });
        }

        if (!selected.NoS) {
            services += 'NoS,';
        }

        let dataExtension: IExtension[] = [];
        if (selected[KEY.UTILITY_SERVICE].length > 0) {
            dataExtension = [...selected[KEY.UTILITY_SERVICE]].map((i) => {
                return { id: i.id, name: i.name };
            });
        }

        const otherParams: any = { ...result.current };
        if (otherParams) {
            delete otherParams.utm_source;
            delete otherParams.token;
        }
        // note he thong chon stylist tot nhat - stylist chuyen salon

        const newNoteSaleInfo = selected.listServiceSaleInfo
            .map((item) => item.note)
            .join(',');

        const noteBooking = `${selected[KEY.NOTE]}${selected[KEY.NOTE] && selected[KEY.NOTE_STYLIST_MOVE_SALON]
                ? '. '
                : ''
            }${selected[KEY.NOTE_STYLIST_MOVE_SALON]} ${newNoteSaleInfo}`;

        let salonId = selected[KEY.SALON].id;
        const dataHour = {
            // [KEY.BOOK_HOUR_ID]: selected[KEY.HOUR].bookHourId,
            [KEY.BOOK_HOURS]: selected[KEY.BOOK_HOURS],
            [KEY.SUB_HOUR_ID]: selected[KEY.HOUR].subHourId,
            [KEY.SECOND_SUB_HOUR_ID]: selected[KEY.HOUR].secondSubHourId,
        };

        if (selectedSalonBackUp[KEY.SALON].id > 0) {
            salonId = selectedSalonBackUp[KEY.SALON].id;
            dataHour[KEY.BOOK_HOURS] = selected[KEY.BOOK_HOURS];
            dataHour[KEY.SUB_HOUR_ID] = selectedSalonBackUp[KEY.HOUR].subHourId;
            dataHour[KEY.SECOND_SUB_HOUR_ID] =
                selectedSalonBackUp[KEY.HOUR].secondSubHourId;
        }

        let listCampaign: any = [];
        if (selected[KEY.CHOSEN_CAMPAIGN].length) {
            listCampaign = selected[KEY.CHOSEN_CAMPAIGN]
                .filter((campaign) => campaign.valid)
                .map((campaign) => {
                    return {
                        id: campaign.campaignId,
                        name: campaign.name,
                    };
                });
        }
        const totalMoney = selected[KEY.CHOSEN_SERVICES].reduce(
            (acc, curr) => acc + formatPriceServiceToNum(curr?.price || ''),
            0,
        );
        const data = {
            [KEY.SALON_ID]: salonId,
            [KEY.PHONE]: selected[KEY.PHONE],
            [KEY.STYLIST_ID]: selected[KEY.STYLIST].id,
            [KEY.SKINNER_ID]: selected[KEY.SKINNER_ID],
            [KEY.DATE_BOOK]: selected[KEY.DATE_BOOK],
            [KEY.SERVICES]: services.slice(0, services.length - 1),
            [KEY.CHOSEN_SERVICES]: formatChosenService.length
                ? JSON.stringify(formatChosenService)
                : '',
            // [KEY.BOOK_HOUR_ID]: dataHour[KEY.BOOK_HOUR_ID],
            [KEY.BOOK_HOURS]: dataHour[KEY.BOOK_HOURS],
            [KEY.SUB_HOUR_ID]: dataHour[KEY.SUB_HOUR_ID],
            [KEY.SECOND_SUB_HOUR_ID]: dataHour[KEY.SECOND_SUB_HOUR_ID],
            [KEY.BOOK_AT]:
                trackingData.source === 'customer_ios'
                    ? 0
                    : trackingData.source === 'customer_android'
                        ? 2
                        : trackingData.utmSource === 'rn_android'
                            ? 4
                            : trackingData.utmSource === 'rn_ios'
                                ? 5
                                : trackingData.utmSource === 'tikiapp'
                                    ? 6
                                    : 3,
            [KEY.CAMPAIGN]: selected[KEY.CAMPAIGN],
            [KEY.USER_ID]: userId || 0,
            [KEY.NOTE]: noteBooking,
            [KEY.RECEIVE_ADVICE]: selected[KEY.RECEIVE_ADVICE],
            [KEY.UTILITY_SERVICE]: JSON.stringify(dataExtension),
            [KEY.ACCEPT_MAKE_PHOTO]: selected[KEY.ACCEPT_MAKE_PHOTO],
            [KEY.LITTLE_ADVICE]: selected[KEY.LITTLE_ADVICE],
            [KEY.NUMBER_PERSONS]: selected[KEY.NUMBER_PERSONS],
            utmSource: result.current.utm_source || '',
            params: JSON.stringify(otherParams),
            campaigns: listCampaign,
            isMaster: isBookStylistMaster,
            adviceUND: selected.adviceUND,
            adviceProduct: selected.adviceProduct,
            allowChange,
            muonCaoMat: selected.muonCaoMat,
        };

        const link = '/booking/success';
        if (key === 'create') {
            const bookingParam = {
                ...data,
            };
            try {
                const bookingData: any = await API.booKingV2(bookingParam);
                setSpin(false);
                const config = {
                    message: 'Thông Báo',
                    description: 'Đặt Lịch Thành Công!',
                    duration: 1,
                    onClose: () => {
                        const message = {
                            type: 'booking_success',
                            message: 'click_button_success',
                        };
                        if (inApp) {
                            if (trackingData.utmSource === 'tikiapp') {
                                // @ts-ignore
                                my.postMessage(JSON.stringify(message));
                            }
                            if (
                                trackingData.utmSource === 'rn_android' ||
                                trackingData.utmSource === 'rn_ios'
                            ) {
                                // @ts-ignore
                                window.ReactNativeWebView.postMessage(
                                    JSON.stringify(message),
                                );
                            }
                        }
                        history.push({
                            pathname: link,
                            state: {
                                bookingData: bookingData?.booking
                                    ? { ...bookingData.booking, totalMoney }
                                    : undefined,
                                selected,
                            },
                        });
                        // TRACKING
                        const dataTracking = {
                            pageId: trackingConst.Pages.BOOKING_SINGLE_PAGE,
                            screenId:
                                trackingConst.Screens.BOOKING_SUCCESS_SCREEN,
                            eventId:
                                trackingConst.Events.GO_TO_BOOKING_SUCCESS_PAGE,
                            tokenKey: trackingData.token,
                            source: trackingData.source,
                            utmSource: trackingData.utmSource,
                            data: {
                                phone: selected[KEY.PHONE],
                                salonId: selected[KEY.SALON].id.toString(),
                                dateBook: selected[KEY.DATE_BOOK],
                                stylistId: selected[KEY.STYLIST].id.toString(),
                                hoursAvailable: selected[
                                    KEY.AVAILABLE_HOURS
                                ].toString(),
                                chosenServices: JSON.stringify(
                                    selected[KEY.CHOSEN_SERVICES],
                                ),
                                hourBook: selected[KEY.HOUR].hour,
                                bookingId: bookingData.booking.id,
                                customerId: userId || 0,
                                skinnerId: selected[KEY.SKINNER_ID].toString(),
                            },
                            extendData: otherParams,
                        };
                        const phoneBooking = `+84${selected[KEY.PHONE]
                            .replace(/[^0-9]/g, '')
                            .trim()
                            .slice(1)}`;
                        localStorage.setItem('phone_booking', phoneBooking);
                        postTracking(dataTracking);
                    },
                    className: 'success',
                };
                if (bookingData?.booking?.id) {
                    if (bookingData.isUseOtp) {
                        setVisibleOtp(true);
                        setBookingData(bookingData.booking);
                    } else {
                        setBookingSuccess(bookingData.booking.id);
                        notification.success(config);
                    }
                }
            } catch (error) {
                setSpin(false);
                console.log(error?.response?.data?.message, ' error?.response');
                const config = {
                    message: 'Thông Báo',
                    description:
                        error?.response?.data?.message ||
                        'Có lỗi xảy ra, vui lòng thử lại sau!',
                    duration: 3,
                    className: 'error',
                };
                // API.postError({ message: config.description });
                if (error?.response?.status === 403) {
                    setShowModalLogin(true);
                    setTitleModalLogin(
                        'BẠN ĐÃ CÓ LỊCH ĐẶT, BẠN VUI LÒNG ĐĂNG NHẬP ĐỂ ĐỔI LỊCH',
                    );
                    return;
                }
                notification.error(config);
            }
        }
    };

    const onChangeStep = (isNext: boolean) => {
        if (isNext && step <= 4) {
            const isInActive: boolean =
                (step === 0 &&
                    selected.hour.bookHourId === 0 &&
                    selectedSalonBackUp.hour.bookHourId === 0) ||
                selected.salon.id === 0 ||
                selected.chosenService.length === 0 ||
                (step === 4 && selected.chosenCampaign.length === 0);
            const dataTracking = {
                pageId: trackingConst.Pages.BOOKING_SINGLE_PAGE,
                screenId:
                    step === 1
                        ? trackingConst.Screens.BOOKING_SELECT_SALON_SCREEN
                        : step === 2
                            ? trackingConst.Screens.BOOKING_SELECT_SERVICE_SCREEN
                            : step === 4
                                ? trackingConst.Screens.BOOKING_CAMPAIGN_SCREEN
                                : trackingConst.Screens
                                    .BOOKING_SELECT_BOOKING_SINGLE_SCREEN,
                eventId: isInActive
                    ? trackingConst.Events.CLICK_BUTTON_SUCCESS_INACTIVE
                    : trackingConst.Events.CLICK_BUTTON_BOOKING,
                tokenKey: trackingData.token,
                source: trackingData.source,
                utmSource: trackingData.utmSource,
                data: {
                    phone: selected[KEY.PHONE],
                    salonId: selected[KEY.SALON].id.toString(),
                    dateBook: selected[KEY.DATE_BOOK],
                    stylistId: selected[KEY.STYLIST].id.toString(),
                    hoursAvailable: selected[KEY.AVAILABLE_HOURS].toString(),
                    chosenServices: JSON.stringify(
                        selected[KEY.CHOSEN_SERVICES],
                    ),
                    hourBook: selected[KEY.HOUR].hour,
                    step: selected[KEY.STEP],
                },
            };
            switch (step) {
                case 0:
                    if (selected[KEY.SALON].id !== 0) {
                        setHasClickNextInSalon(1);
                        if (selected[KEY.CHOSEN_SERVICES].length > 0) {
                            setHasClickNextInService(1);
                            if (
                                !selected[KEY.HOUR].bookHourId &&
                                !selectedSalonBackUp[KEY.HOUR].bookHourId
                            ) {
                                setHasClickNextInTime(
                                    (prevState) => prevState + 1,
                                );
                                postTracking(dataTracking);
                            }
                        } else {
                            setHasClickNextInService(0);
                            postTracking(dataTracking);
                        }
                    } else {
                        setHasClickNextInSalon(0);
                        postTracking(dataTracking);
                    }
                    if (
                        selected[KEY.SALON].id &&
                        selected[KEY.BOOK_HOURS].length ===
                            selected[KEY.GROUP_BOOKING] &&
                        (selected[KEY.BOOK_HOURS].length !== 0 ||
                            selectedSalonBackUp[KEY.HOUR].bookHourId !== 0)
                    ) {
                        handleBooking('create');
                    }
                    break;
                case 1:
                    if (selected[KEY.SALON].id > 0) {
                        setSelected((state) => ({
                            ...state,
                            [KEY.STEP]: 2,
                        }));
                        setHasClickNextInService(1);
                    }
                    break;
                case 2:
                    if (selected[KEY.CHOSEN_SERVICES].length > 0) {
                        setHasClickNextInService((prevState) => prevState + 1);
                        onChangeSelected(KEY.STEP, 0);
                        setIsClickNextInService(true);
                        postTracking(dataTracking);
                    } else {
                        setHasClickNextInService(0);
                        postTracking(dataTracking);
                    }
                    break;
                case 4:
                    if (selected.chosenCampaign.length > 0) {
                        onChangeSelected(KEY.STEP, 2);
                    }
                    postTracking(dataTracking);
                    break;
                default:
                    break;
            }
        } else {
            if (step === 1) {
                setHasClickNextInService(1);
                if (selected[KEY.SALON].id !== 0) {
                    setHasClickNextInSalon(1);
                } else {
                    setHasClickNextInSalon(0);
                }
            }
            if (step === 2) {
                if (selected[KEY.SALON].id > 0) {
                    if (selected[KEY.CHOSEN_SERVICES].length > 0) {
                        setHasClickNextInService((prevState) => prevState + 1);
                    } else {
                        setHasClickNextInService(0);
                    }
                } else {
                    setHasClickNextInSalon(0);
                }
            }
            if (step > 0) {
                const count =
                    JSON.parse(localStorage.getItem('count_step2') || '0') + 1;
                localStorage.setItem('count_step2', count);
                if (step === 4) {
                    onChangeSelected(KEY.STEP, 2);
                } else if (step === 5) {
                    onChangeSelected(KEY.STEP, 4);
                } else {
                    onChangeSelected(KEY.STEP, 0);
                }
            }
        }
    };

    const getRoleMember = () => {
        if (selected[KEY.PHONE]) {
            const params = {
                phone: selected[KEY.PHONE],
                salonId: selected[KEY.SALON].id,
            };
            fetchRole(params);
        }
    };

    const changeServiceWithChangeStylistDateBook = async (
        listService: any,
        listChooseService: IService[],
        datebook: string,
        stylist: IStylistSelected,
    ) => {
        if (datebook && listChooseService.length > 0) {
            // Change id chosen services with change selected date book
            let listNewChooseServiceWeekend: any = [];
            let listNewChosenServices: any[] = [];
            listNewChosenServices = listChooseService.map((item) => {
                let newItem = { ...item };
                listService.rulesSpecialDay.map((rules: any) => {
                    const isServiceSpecial =
                        item.serviceId === rules?.serviceId ||
                        rules?.serviceSpecial?.serviceId === item.serviceId ||
                        rules?.serviceSpecialStylist?.serviceId ===
                            item.serviceId ||
                        rules?.serviceStylist?.serviceId === item.serviceId;
                    const isBookStylistMaster = stylist?.id
                        ? rules?.stylistLevelId?.includes(stylist.skillLevel) &&
                        rules?.serviceSpecialStylist &&
                        rules?.serviceStylist
                        : false;
                    const isRuleDate = rules?.date?.includes(datebook);
                    if (isServiceSpecial) {
                        const serviceIsWeekday = selected.listAllService.find(
                            (item: any) => item.serviceId === rules?.serviceId,
                        );
                        setIsBookStylistMaster(isBookStylistMaster);
                        if (stylist && stylist?.id !== 0) {
                            // stylist dang chon la master
                            if (isBookStylistMaster) {
                                if (
                                    isRuleDate &&
                                    rules?.serviceSpecialStylist
                                ) {
                                    newItem = rules.serviceSpecialStylist;
                                } else if (
                                    !isRuleDate &&
                                    rules?.serviceStylist
                                ) {
                                    newItem = rules.serviceStylist;
                                }
                                listNewChooseServiceWeekend.push(newItem);
                            } else {
                                // stylist dang chon la stylist thuong
                                if (isRuleDate && rules?.serviceSpecial) {
                                    newItem = rules?.serviceSpecial || item;
                                } else {
                                    newItem = serviceIsWeekday || item;
                                }
                                listNewChooseServiceWeekend.push(newItem);
                            }
                        } else if (
                            // stylist auto
                            item.serviceId === rules?.serviceId ||
                            rules?.serviceSpecial?.serviceId === item.serviceId
                        ) {
                            // dịch vụ đang chọn là trong tuần va cuoi tuan
                            if (isRuleDate && rules.serviceSpecial) {
                                newItem = rules.serviceSpecial;
                            } else {
                                newItem = serviceIsWeekday || item;
                            }
                            listNewChooseServiceWeekend.push(newItem);
                        } else if (
                            // dịch vụ đang chọn là stylist master trong tuần va cuối tuần
                            rules?.serviceSpecialStylist?.serviceId ===
                                item.serviceId ||
                            rules?.serviceStylist?.serviceId === item.serviceId
                        ) {
                            if (isRuleDate && rules?.serviceSpecialStylist) {
                                newItem = rules.serviceSpecialStylist;
                            } else if (!isRuleDate && rules?.serviceStylist) {
                                newItem = rules.serviceStylist;
                            }
                            listNewChooseServiceWeekend.push(newItem);
                        }
                    }
                    return rules;
                });
                return {
                    ...newItem,
                    serviceImage: item.serviceImage ? item.serviceImage : [],
                };
            });

            let listCampaign: ListCampaignType = { salon: [], customer: [] };
            let newChosenCampaign: CampaignType[] = [];

            if (selected.chosenCampaign.length) {
                // tinh lai gia dv ap dung campaign
                const listCampaignChosenId = selected.chosenCampaign.map(
                    (item) => item.campaignId,
                );
                const data: any = await fetchCampaign(
                    listNewChosenServices,
                    selected.chosenCampaign,
                    selected.customerPhone,
                    selected.salon.id,
                );
                try {
                    if (data) {
                        const chosenCampaign: CampaignType[] = [];
                        data.customer.map((item: CampaignType) => {
                            if (
                                listCampaignChosenId.includes(item.campaignId)
                            ) {
                                chosenCampaign.push(item);
                            }
                        });
                        data.salon.map((item: CampaignType) => {
                            if (
                                listCampaignChosenId.includes(item.campaignId)
                            ) {
                                chosenCampaign.push(item);
                            }
                        });
                        listCampaign = data;
                        newChosenCampaign = chosenCampaign;
                        listNewChosenServices = setChosenServiceWithCampaign(
                            listNewChosenServices,
                            newChosenCampaign,
                            selected.listAllService,
                        );
                        listNewChooseServiceWeekend = listNewChooseServiceWeekend.map(
                            (obj: any) =>
                                listNewChosenServices.find(
                                    (o) => o.id === obj.id,
                                ) || obj,
                        );
                    }
                } catch (error) { }
            }
            setSelected((prevState) => ({
                ...prevState,
                [KEY.CHOSEN_SERVICES]: listNewChosenServices,
                [KEY.CHOSEN_SERVICES_WEEKEND]: listNewChooseServiceWeekend,
                [KEY.LIST_CAMPAIGN]: listCampaign,
                [KEY.CHOSEN_CAMPAIGN]: newChosenCampaign,
            }));
        }
    };

    const chosenServiceWithRebooking = (
        listChooseService: number[],
        listService: any,
    ) => {
        // dat lai dich vu lich su cat
        const listNewChosenServices: IService[] = [];

        if (selected.listAllService.length) {
            selected.listAllService.map((service: any) => {
                if (listChooseService.includes(service.serviceId)) {
                    listNewChosenServices.push(service);
                } else {
                    listService.rulesSpecialDay.map((rules: any) => {
                        if (
                            rules?.serviceSpecial?.serviceId &&
                            listChooseService.includes(
                                rules.serviceSpecial.serviceId,
                            )
                        ) {
                            listNewChosenServices.push(rules.serviceSpecial);
                        }

                        if (
                            rules?.serviceSpecialStylist?.serviceId &&
                            listChooseService.includes(
                                rules.serviceSpecialStylist.serviceId,
                            )
                        ) {
                            listNewChosenServices.push(
                                rules.serviceSpecialStylist,
                            );
                        }

                        if (
                            rules?.serviceStylist?.serviceId &&
                            listChooseService.includes(
                                rules.serviceStylist.serviceId,
                            )
                        ) {
                            listNewChosenServices.push(rules.serviceStylist);
                        }
                    });
                }
                return service;
            });
        }
        const removeDuplicateGroup: IService[] = listNewChosenServices.filter(
            (sv: any, index: any, self: any) =>
                self.findIndex(
                    (item: any) => item.group === sv.group || sv.group !== 0,
                ) === index,
        );

        return removeDuplicateGroup;
    };

    const getListServices = async () => {
        setIsLoadingServices(true);
        const params: any = {
            salonId: selected[KEY.SALON].id,
            customerPhone: selected[KEY.PHONE],
            lang,
        };
        if (selected[KEY.CHOSEN_SERVICES].length) {
            const listServiceIDs = selected[KEY.CHOSEN_SERVICES]
                .map((service) => service.serviceId)
                .join(',');
            params.choosenServiceIds = listServiceIDs;
        } else {
            params.choosenServiceIds = '0';
        }
        if (result.current?.chosenService) {
            params.choosenServiceIds = result.current.chosenService;
        }
        let dfListService = selected[KEY.CHOSEN_SERVICES];
        let dfListServiceWeekend = selected[KEY.CHOSEN_SERVICES];
        try {
            const res: any = await API.getListServiceV5(params);
            const list = res?.data || null;
            let newData: any = [];
            if (!list) return;
            if (list) {
                let listAllService: IService[] = [];
                setRuleShowBooking(list.ruleShowBookings);
                if (!list.isSaveServiceChoosen) {
                    dfListService = [];
                    dfListServiceWeekend = [];
                    if (
                        selected[KEY.CHOSEN_SERVICES].length ||
                        result.current?.chosenService
                    ) {
                        setHasClickNextInService(-1);
                    }
                } else if (
                    list.isSaveServiceChoosen &&
                    result.current?.chosenService
                ) {
                    const chosenServiceIds = result.current.chosenService
                        .split(',')
                        .map((item: string) => Number(item));
                    const resultChosenService = chosenServiceWithRebooking(
                        chosenServiceIds,
                        list,
                    );
                    dfListService = resultChosenService;
                }
                if (list.data) {
                    newData = list.data.map((category: any) => {
                        return {
                            ...category,
                            serviceGroups: category.serviceGroups.map(
                                (svGroup: any) => {
                                    const newListService = svGroup.services.map(
                                        (service: any) => {
                                            const newService = {
                                                ...service,
                                                oldPriceNum: service?.oldPrice
                                                    ? formatPriceServiceToNum(
                                                        service.oldPrice,
                                                    )
                                                    : service.priceNum,
                                                oldPrice:
                                                    service?.oldPrice ||
                                                    service.price,
                                            };
                                            listAllService.push(newService);
                                            return newService;
                                        },
                                    );
                                    return {
                                        ...svGroup,
                                        services: newListService,
                                    };
                                },
                            ),
                        };
                    });
                }
                if (list?.rulesSpecialDay?.length > 0) {
                    list.rulesSpecialDay.map((rule: any) => {
                        if (rule?.serviceSpecial)
                            listAllService.push(rule.serviceSpecial);
                        if (rule?.serviceStylist)
                            listAllService.push(rule.serviceStylist);
                        if (rule?.serviceSpecialStylist)
                            listAllService.push(rule.serviceSpecialStylist);
                    });
                }
                listAllService = listAllService.filter(
                    (item, index, self) =>
                        index ===
                        self.findIndex(
                            (selfService) =>
                                selfService.serviceId === item.serviceId,
                        ),
                );

                result.current.chosenService = '';
                setSelected((state) => ({
                    ...state,
                    [KEY.CHOSEN_SERVICES]: dfListService,
                    [KEY.CHOSEN_SERVICES_WEEKEND]: dfListServiceWeekend,
                    [KEY.LIST_SERVICES]: { ...list, data: newData },
                    [KEY.LIST_ALL_SERVICES]: listAllService,
                }));
            }
            // handle guide screens
            if (list.guideScreens) setGuideService(list.guideScreens);
        } catch (error) {
            console.log(error);
        }
        setIsLoadingServices(false);
    };

    const getListCampaignTags = async () => {
        try {
            const data: any = await fetchCampaign(
                [],
                [],
                selected.customerPhone,
                selected.salon.id,
            );
            if (data?.best?.length) {
                setListCampaignTags(data.best);
            }
        } catch (error) { }
    };

    function moveSalonStylistbackUp(data: any) {
        if (data.salon.id > 0) {
            setSelected((state) => ({
                ...state,
                [KEY.SALON]: data[KEY.SALON],
                [KEY.STYLIST]: data[KEY.STYLIST],
                [KEY.STEP]: 0,
                [KEY.HOUR]: dfHour,
                [KEY.NOTE_STYLIST_MOVE_SALON]: '',
                [KEY.DATE_BOOK]: data[KEY.DATE_BOOK] || selected[KEY.DATE_BOOK],
                [KEY.NUMBER_PERSONS]: 0,
                [KEY.SKINNER_ID]: 0,
            }));
        } else {
            setSelected((state) => ({
                ...state,
                [KEY.DATE_BOOK]: TODAY,
                [KEY.STYLIST]: { id: 0, name: '' },
                [KEY.SALON]: {
                    id: 0,
                    name: '',
                    openingDate: '',
                    warnText: '',
                    addressNew: '',
                    address: '',
                    bookManyPeople: '',
                },
                [KEY.NOS]: true,
                [KEY.CHOSEN_SERVICES]: [],
                [KEY.CHOSEN_SERVICES_WEEKEND]: [],
                [KEY.HOUR]: dfHour,
                [KEY.STEP]: result.current[KEY.STEP] || 0,
                [KEY.CAMPAIGN]: undefined,
                [KEY.AVAILABLE_HOURS]: 0,
                [KEY.NOTE]: '',
                [KEY.NOTE_STYLIST_MOVE_SALON]: '',
                [KEY.LIST_SERVICES]: {},
                [KEY.RECEIVE_ADVICE]: true,
                [KEY.UTILITY_SERVICE]: [],
                [KEY.ACCEPT_MAKE_PHOTO]: true,
                [KEY.LITTLE_ADVICE]: false,
                [KEY.NUMBER_PERSONS]: 0,
                [KEY.SKINNER_ID]: 0,
            }));
        }
        const params = setQuery(selected, { salonId: data[KEY.SALON].id });

        history.push({
            pathname: '/booking',
            search: params,
        });
    }

    const postTrackingGotoScreen = (step: any) => {
        const dataCustomer: any = [
            {
                phone: selected[KEY.PHONE],
            },
            {
                phone: selected[KEY.PHONE],
                salonId: selected[KEY.SALON].id.toString(),
            },
            {
                phone: selected[KEY.PHONE],
                salonId: selected[KEY.SALON].id.toString(),
                dateBook: selected[KEY.DATE_BOOK],
                stylistId: selected[KEY.STYLIST].id.toString(),
                chosenServices: JSON.stringify(selected[KEY.CHOSEN_SERVICES]),
            },
            {
                phone: selected[KEY.PHONE],
                salonId: selected[KEY.SALON].id.toString(),
                dateBook: selected[KEY.DATE_BOOK],
                stylistId: selected[KEY.STYLIST].id.toString(),
                chosenServices: JSON.stringify(selected[KEY.CHOSEN_SERVICES]),
                hoursAvailable: selected[KEY.AVAILABLE_HOURS].toString(),
                hourBook: selected[KEY.HOUR].hour,
                bookingId: bookingId.current,
            },
            {
                phone: selected[KEY.PHONE],
                salonId: selected[KEY.SALON].id.toString(),
                dateBook: selected[KEY.DATE_BOOK],
                stylistId: selected[KEY.STYLIST].id.toString(),
                chosenServices: JSON.stringify(selected[KEY.CHOSEN_SERVICES]),
            },
        ];

        const screenEvents: any = [
            {
                screenId:
                    trackingConst.Screens.BOOKING_SELECT_BOOKING_SINGLE_SCREEN,
                eventId:
                    trackingConst.Events
                        .GO_TO_BOOKING_SELECT_BOOKING_SINGLE_SCREEN,
            },
            {
                screenId: trackingConst.Screens.BOOKING_SELECT_SALON_SCREEN,
                eventId: trackingConst.Events.GO_TO_BOOKING_SELECT_SALON_SCREEN,
            },
            {
                screenId: trackingConst.Screens.BOOKING_SELECT_SERVICE_SCREEN,
                eventId:
                    trackingConst.Events.GO_TO_BOOKING_SELECT_SERVICE_SCREEN,
            },
            {
                screenId:
                    trackingConst.Screens.BOOKING_SELECT_DETAIL_SERVICE_SCREEN,
                eventId:
                    trackingConst.Events.GO_TO_BOOKING_DETAIL_SERVICE_SCREEN,
            },
            {
                screenId: trackingConst.Screens.BOOKING_SELECT_SERVICE_SCREEN,
                eventId: trackingConst.Events.GO_TO_BOOKING_CAMPAIGN_SCREEN,
            },
            {
                screenId: trackingConst.Screens.BOOKING_CAMPAIGN_SCREEN,
                eventId: trackingConst.Events.GO_TO_BOOKING_CAMPAIGN_SCREEN,
            },
        ];

        const dataTracking = {
            pageId: trackingConst.Pages.BOOKING_SINGLE_PAGE,
            screenId: screenEvents[step].screenId,
            eventId: screenEvents[step].eventId,
            tokenKey: trackingData.token,
            source: trackingData.source,
            utmSource: trackingData.utmSource,
            data: { ...dataCustomer[step], customerId: userId || 0 },
        };
        postTracking(dataTracking);
    };

    const setParamUrl = () => {
        const paramUrl: any = queryString.parse(location.search);
        const { step, token = '', phone } = paramUrl;
        if (parseInt(step) !== selected[KEY.STEP]) {
            const ortherParams = token
                ? {
                    token,
                }
                : {};
            const params = setQuery(selected, ortherParams);
            if (step || phone) {
                history.push({
                    pathname: '/booking',
                    search: params,
                });
            } else {
                history.replace({
                    pathname: '/booking',
                    search: params,
                });
            }
        }
    };

    const checkSalonOff = async (salonId: number, screenId: string) => {
        let response: any = null;
        let res: any = null;
        try {
            res = await API.GetSalonOff({ salonId });
            response = res?.data || null;
            if (!response) return;
            setIsShowModalSalonOff(true);
            if (response.message) {
                setDataModalSalonOff({
                    message: response.message,
                    salonBackups: response.salonBackUp,
                    salonId,
                    screenId,
                });
                setSelected((state) => ({
                    ...state,
                    [KEY.SALON]: {
                        id: 0,
                        name: '',
                        openingDate: '',
                        warnText: '',
                        addressNew: '',
                        address: '',
                        bookManyPeople: '',
                    },
                }));
                const params = setQuery(selected, {
                    salonId: 0,
                });
                history.push({
                    pathname: '/booking',
                    search: params,
                });
            }
        } catch (e) {
            console.log(e);
        }
        return response;
    };

    const scrollToSelectTimeAndService = () => {
        const element = document.querySelector(
            '#serviceAttributeId',
        ) as HTMLDivElement;
        const offset = 50;
        const bodyRect = document.body.getBoundingClientRect().top;
        const elementRect = element.getBoundingClientRect().top;
        const elementPosition = elementRect - bodyRect;
        const offsetPosition = elementPosition - offset;
        window.scrollTo({
            top: offsetPosition,
        });
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        isFirstRender.current = false;
        const paramUrl = queryString.parse(location.search);
        const { token = '', salonId = 0 } = paramUrl;
        let params = setQuery(selected);
        if (step !== selected[KEY.STEP]) {
            const otherParams = token
                ? {
                    token,
                }
                : {};
            params = setQuery(selected, otherParams);
        }
        if (
            !(
                Number.isInteger(Number(selected[KEY.SALON].id)) &&
                Number(selected[KEY.SALON].id >= 0)
            )
        ) {
            params = setQuery({
                ...selected,
                [KEY.SALON]: {
                    id: 0,
                    name: '',
                    openingDate: '',
                    warnText: '',
                    addressNew: '',
                    address: '',
                    bookManyPeople: '',
                },
            });
        }
        if (validatePhone(selected[KEY.PHONE])) {
            onChangeSelected(KEY.PHONE, '');
        }
        if (Number(salonId) > 0 && Number(step) > 1) {
            onChangeSelected(KEY.STEP, 0);
        }
        if (Number(salonId) === 0 && Number(step) > 1) {
            onChangeStep(false);
        }
    }, []);

    useEffect(() => {
        const paramUrl: any = queryString.parse(location.search);
        const { step = -1, salonId = 0 } = paramUrl;
        if (!isFirstRender.current) {
            if (Number(step) !== selected[KEY.STEP]) {
                onChangeSelected(
                    KEY.STEP,
                    Number(step) > 0 && Number(step) < 6 ? Number(step) : 0,
                );
            }
            if (Number(salonId) !== selected[KEY.SALON].id) {
                onChangeSelected(KEY.SALON, {
                    id: Number(salonId),
                    name: '',
                    openingDate: '',
                });
            }
        }
    }, [location.search]);

    useEffect(() => {
        resetSelectedBackUp();

        if (!isFirstRender.current) {
            setParamUrl();
        }

        // reset choice hour
        if (step === 2 && selected[KEY.HOUR].bookHourId) {
            setSelected((state) => ({
                ...state,
                [KEY.HOUR]: dfHour,
            }));
        }
        if (
            prevStep === 2 &&
            selected[KEY.STEP] === 0 &&
            selected[KEY.CHOSEN_SERVICES].length > 0
        ) {
            scrollToSelectTimeAndService();
        }
        if (prevStep === 1 && selected[KEY.STEP] === 0) {
            window.scrollTo(0, 0);
        }
        // TRACKING
        postTrackingGotoScreen(selected[KEY.STEP]);
    }, [selected[KEY.STEP]]);

    useEffect(() => {
        const errorPhone = validatePhone(selected[KEY.PHONE]);
        if (selected[KEY.SALON].id > 0 && !errorPhone) {
            checkSalonOff(
                selected[KEY.SALON].id,
                trackingConst.Screens.BOOKING_SELECT_BOOKING_SINGLE_SCREEN,
            );
        }
        if (
            Number.isInteger(Number(selected[KEY.SALON].id)) &&
            Number(selected[KEY.SALON].id >= 0) &&
            !errorPhone
        ) {
            getRoleMember();
        }
    }, [selected[KEY.SALON].id, selected[KEY.PHONE]]);

    useEffect(() => {
        const errorPhone = validatePhone(selected[KEY.PHONE]);
        if (selected[KEY.SALON].id > 0 && !errorPhone && lang) {
            getListServices();
            getListCampaignTags();
        }
    }, [selected[KEY.SALON].id, selected[KEY.PHONE], lang]);

    useEffect(() => {
        const errorPhone = validatePhone(selected[KEY.PHONE]);
        if (errorPhone) {
            setShowModalBooking(true);
            setCustomerPhone('');
        } else {
            setCustomerPhone(selected[KEY.PHONE]);
        }
    }, [selected[KEY.PHONE]]);

    useEffect(() => {
        const errorPhone = validatePhone(selected[KEY.PHONE]);
        if (errorPhone) return;
        if (showModalLogin) {
            setCustomerPhone(selected[KEY.PHONE]);
        } else {
            setCustomerPhone('');
        }
    }, [showModalLogin, selected[KEY.PHONE]]);

    const renderServiceScreen = () => {
        return (
            <ServiceScreen
                onChangeStep={(value: any) => onChangeSelected(KEY.STEP, value)}
                onChangeChosenService={(value: IService[]) => {
                    onChangeSelected(KEY.CHOSEN_SERVICES, value);
                }}
                onChangeListServiceScreen={(list: IListService[]) =>
                    onChangeSelected(KEY.LIST_ALL_SERVICES, list)
                }
                selected={selected}
                chosenService={selected[KEY.CHOSEN_SERVICES]}
                setListCampaign={(listCampaign: any) =>
                    onChangeSelected(KEY.LIST_CAMPAIGN, listCampaign)
                }
                setChosenCampaign={(listCampaign: any) =>
                    onChangeSelected(KEY.CHOSEN_CAMPAIGN, listCampaign)
                }
                gotoCampaignScreen={() => onChangeSelected(KEY.STEP, 4)}
                ruleShowBooking={ruleShowBooking}
                setPreviOusChosenServices={() =>
                    setPreviOusChosenServices(selected.chosenService)
                }
                setAllowChange={setAllowChange}
                allowChange={allowChange}
                listCampaignTags={listCampaignTags}
                gotoCampaignDetail={(detailCampaign: CampaignType) => {
                    onChangeSelected(KEY.STEP, 5);
                    setCampaignDetailSelected(detailCampaign);
                }}
            />
        );
    };

    if (process.env.NODE_ENV === 'development')
        console.log('%cSelected: ', 'color: #da7900', selected);

    useEffect(() => {
        if (allowChange) {
            setIsClickNextInService(true);
            setHasClickNextInService(hasClickNextInService + 1);
        }
    }, [allowChange]);

    return (
        <div className="booking-screen">
            <div
                className={`body relative ${result.current.inApp ||
                        appSources.includes(result.current.source)
                        ? 'body-mobile'
                        : ''
                    }`}
            >
                {step > 1 && <SalonNewMessage salon={selected[KEY.SALON]} />}

                {step >= 0 && step !== 3 && step !== 2 ? (
                    <TitleScreen
                        onChangeStep={onChangeStep}
                        listService={[]}
                        step={step}
                        paramUrl={result.current}
                    />
                ) : null}

                {step === 0 && (
                    <>
                        <MainScreen
                            hasClickInSalon={hasClickInSalon}
                            hasClickNextStep={hasClickNextInTime}
                            onChangeSelected={onChangeSelected}
                            moveSalonStylistbackUp={moveSalonStylistbackUp}
                            selected={selected}
                            changeCheckBox={() => setHasClickNextInTime(0)}
                            setHasClickNextInSalon={setHasClickNextInSalon}
                            onChangeSalon={onChangeSalon}
                            hasClickInService={hasClickNextInService}
                            setHasClickInService={setHasClickNextInService}
                            selectedSalonBackUp={selectedSalonBackUp}
                            onChangeSelectedBackUp={onChangeSelectedBackUp}
                            setIsSelectedSalonNear={setIsSelectedSalonNear}
                            guideService={guideService}
                            // Extension props
                            isLoadingServices={isLoadingServices}
                            isBookStylistMaster={isBookStylistMaster}
                            changeServiceWithChangeStylistDateBook={
                                changeServiceWithChangeStylistDateBook
                            }
                            isClickNextInService={isClickNextInService}
                            previOusChosenServices={previOusChosenServices}
                            prevStep={prevStep}
                            allowChange={allowChange}
                        />
                    </>
                )}

                {step === 1 && (
                    <SalonScreen
                        onChangeFilter={onChangeSalon}
                        setIsSelectedSalonNear={() =>
                            setIsSelectedSalonNear({
                                isAllowAccessLocation: false,
                                isDeniedAccessLocation: false,
                            })
                        }
                        isSelectedSalonNear={isSelectedSalonNear}
                        phone={selected[KEY.PHONE]}
                        checkSalonOff={checkSalonOff}
                        ref={refSalonScreen}
                    />
                )}

                {step === 2 || step === 3 ? renderServiceScreen() : ''}

                {step === 4 || step === 5 ? (
                    <CampaignScreen
                        step={selected.step}
                        phone={selected.customerPhone}
                        salonId={selected.salon.id}
                        chosenService={selected.chosenService}
                        gotoCampaignDetail={() => onChangeSelected(KEY.STEP, 5)}
                        setChosenCampaign={(newListCampaign: CampaignType[]) =>
                            onChangeSelected(
                                KEY.CHOSEN_CAMPAIGN,
                                newListCampaign,
                            )
                        }
                        chosenCampaign={selected.chosenCampaign}
                        listAllServices={selected.listAllService}
                        listCampaign={selected.listCampaign}
                        onChangeChosenService={(value: IService[]) => {
                            onChangeSelected(KEY.CHOSEN_SERVICES, value);
                        }}
                        handleGoToCampaignScreen={() =>
                            onChangeSelected(KEY.STEP, 4)
                        }
                        setListCampaign={(value: CampaignType[]) =>
                            onChangeSelected(KEY.LIST_CAMPAIGN, value)
                        }
                        campaignDetailSelected={campaignDetailSelected}
                        setCampaignDetailSelected={setCampaignDetailSelected}
                    />
                ) : null}

                {step === 0 || step === 4 ? (
                    <ButtonBottom
                        onChangeStep={onChangeStep}
                        step={step}
                        dataBooking={selected}
                        selectedSalonBackUp={selectedSalonBackUp}
                        allowChange={allowChange}
                    />
                ) : null}
            </div>
            <MySpin />
            <ModalBooking
                visible={isShowModalBooking}
                userPhone={userPhone}
                setShowModalBooking={setShowModalBooking}
                selected={selected}
                onChangeSelectedPhone={(value: string) =>
                    onChangeSelected(KEY.PHONE, value)
                }
                getRoleMember={getRoleMember}
            />
            <ModalSalonOff
                visible={isShowModalSalonOff && !isShowModalBooking}
                data={dataModalSalonOff}
                isCloseable={false}
                setVisible={() => setIsShowModalSalonOff(false)}
                phoneBooking={selected[KEY.PHONE]}
                selected={selected}
                onChangeSalon={onChangeSalon}
            />
            {visibleOtp && (
                <ModalOtp
                    selected={selected}
                    visibleOtp={visibleOtp}
                    bookingData={bookingData}
                    onCancel={() => setVisibleOtp(false)}
                />
                )}
            </div>
        );
    };

function mapStateToProps(state: any) {
    if (process.env.NODE_ENV === 'development')
        console.log('%cStore', 'color: #d343dd', state);
    const { trackingData, user, role, common } = state;
    return {
        trackingData,
        userPhone: user.phoneNumber,
        userId: user.userId,
        listSalon: state.salon.listSalon,
        role,
        inApp: common.inApp,
        userData: user,
        showModalLogin: state.common.showModalLogin,
        lang: common.lang,
    };
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        setBookingSuccess: (value: any) => dispatch(setBookingId(value)),
        fetchRole: (value: any) => dispatch(fetchRole(value)),
        setSpin: (value: any) => dispatch(setSpin(value)),
        fetchAllSalon: () => dispatch(fetchAllSalon()),
        setCustomerPhone: (value: string) => dispatch(setBookingPhone(value)),
        setShowModalLogin: (value: boolean) => dispatch(showModalLogin(value)),
        setTitleModalLogin: (value: string) =>
            dispatch(setTitleModalLogin(value)),
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(Booking),
);
