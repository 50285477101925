import { Dispatch } from 'redux';
import API from 'repository';
import {
    removeUnicodeChar,
    diffTimeToday,
    removeDuplicateSalon,
} from 'components/func/Function';
import { store } from '../App';
import {
    SALON_SET_PROVINCE,
    SALON_SET_ALL_SALON,
    SALON_SET_TOP_SALON,
    SALON_SET_TOP_SALON_BOOKING,
} from '../actionTypes/salon';
import { IProvince, ISalon, IListProvince } from '../reducers/salon';

export type setProvinceAction = {
    type: typeof SALON_SET_PROVINCE;
    payload: IListProvince;
};

export type setAllSalonAction = {
    type: typeof SALON_SET_ALL_SALON;
    payload: Array<ISalon>;
};

export type setTopSalonAction = {
    type: typeof SALON_SET_TOP_SALON;
    payload: Array<ISalon>;
};

export type setTopSalonBookingAction = {
    type: typeof SALON_SET_TOP_SALON_BOOKING;
    payload: Array<ISalon>;
};

export const fetchProvince = () => async (dispatch: Dispatch) => {
    try {
        const { data: response }: any = await API.getProvinceStatic();
        if (response.data.length) {
            const newListProvince = response.data.map((province: IProvince) => {
                return {
                    ...province,
                    searchText: removeUnicodeChar(
                        province.cityName,
                    ).toLocaleLowerCase(),
                };
            });
            const list: any = {
                data: newListProvince,
                vungMien: response.vungMien,
            };
            dispatch({
                type: SALON_SET_PROVINCE,
                payload: list,
            });
            return Promise.resolve(list);
        }
        return Promise.resolve([]);
    } catch (error) {
        return Promise.resolve([]);
    }
};

export const fetchAllSalon = () => async (dispatch: Dispatch) => {
    try {
        const response: any = await API.getSalonNewFilterStatic();

        const responseAdviceConfig: any = [];
        const testAtAllSalons =
            responseAdviceConfig.length === 1 && responseAdviceConfig[0] === 0;

        if (response.data.length) {
            const listSalon = response.data.map((salon: ISalon) => {
                return {
                    ...salon,
                    isOpen: !!diffTimeToday(salon.openingDate),
                    selectConsultation:
                        testAtAllSalons ||
                        responseAdviceConfig.includes(salon.id),
                };
            });
            dispatch({
                type: SALON_SET_ALL_SALON,
                payload: listSalon,
            });
            const listTopSalon = response.topSalon.map((salon: ISalon) => {
                return {
                    ...salon,
                    isOpen: !!diffTimeToday(salon.openingDate),
                };
            });
            dispatch({
                type: SALON_SET_TOP_SALON,
                payload: listTopSalon || [],
            });

            return Promise.resolve(listSalon);
        }
        return Promise.resolve([]);
    } catch (error) {
        return Promise.resolve([]);
    }
};

export const fetchTopSalonIbBooking = () => async (dispatch: Dispatch) => {
    try {
        const listSalon = [...store.getState().salon.listSalon];
        const list = removeDuplicateSalon(listSalon);
        const response: any = await API.getTopSalonStatic();
        const sections = response.map((item: any) => {
            const ids = Array.from(new Set(item.salonId));
            const salons = list
                .filter((salon: any) => ids.includes(salon.id))
                .map((salon: any) => {
                    return {
                        ...salon,
                    };
                });
            return {
                title: item.title,
                data: salons,
            };
        });
        dispatch({
            type: SALON_SET_TOP_SALON_BOOKING,
            payload: sections,
        });
        return Promise.resolve(sections);
    } catch (error) {
        return Promise.resolve([]);
    }
};

export const setProvince = (value: IListProvince): setProvinceAction => {
    return {
        type: SALON_SET_PROVINCE,
        payload: value,
    };
};

export const setAllSalon = (value: Array<ISalon>): setAllSalonAction => {
    return {
        type: SALON_SET_ALL_SALON,
        payload: value,
    };
};

export const setTopSalon = (value: Array<ISalon>): setTopSalonAction => {
    return {
        type: SALON_SET_TOP_SALON,
        payload: value,
    };
};

export const setTopSalonBooking = (
    value: Array<ISalon>,
): setTopSalonBookingAction => {
    return {
        type: SALON_SET_TOP_SALON_BOOKING,
        payload: value,
    };
};

export type Action =
    | setProvinceAction
    | setAllSalonAction
    | setTopSalonAction
    | setTopSalonBookingAction;
