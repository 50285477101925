import React from 'react';
import GroupButton from './GroupButton';

const SalonItem: React.FC<{
    salon: any;
}> = ({ salon }) => {
    return salon ? (
        <div className="flex flex-row items-start">
            <div className=" w-[10%]">
                <img src="/icon-footer.svg" alt="" className="w-max" />
            </div>
            <span className="w-[90%] flex flex-col justify-between h-full">
                <span>{salon.addressNew}</span>
                <GroupButton salon={salon} />
            </span>
        </div>
    ) : null;
};

export default SalonItem;
