import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import * as Sentry from '@sentry/react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import App from './App';

const siteKey = process.env.REACT_APP_GOOGLE_RECAPCHA_SITE_KEY || 'missing-key';

if (process.env.REACT_APP_GOOGLE_TAG_MANAGER) {
    const tagManagerArgs = {
        gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER,
        events: {
            sendUserInfo: 'userInfo',
        },
    };

    TagManager.initialize(tagManagerArgs);
}

if (process.env.REACT_APP_SENTRY_DSN) {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration(),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [/^https:\/\/moyo\.vn/],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}

ReactDOM.render(
    <GoogleReCaptchaProvider
        reCaptchaKey={siteKey}
        scriptProps={{
            async: true, // Tải script không đồng bộ
            defer: true, // Trì hoãn tải script
            appendTo: 'head', // Thêm script vào thẻ <head>
        }}
    >
        <App />
    </GoogleReCaptchaProvider>,
    document.getElementById('root'),
);
