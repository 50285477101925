import ClientDiscovers from './client/DiscoverAppClient';

export default {
	getDiscovers() {
		return ClientDiscovers.get('/discovers');
	},
	getDetailDiscovers(id) {
		return ClientDiscovers.get(`/discovers/${id}`);
	},
	setDiscoverLike(id) {
		return ClientDiscovers.get(`/discovers/${id}/like`);
    },
    getDiscoverCustomersLike(id) {
		return ClientDiscovers.get(`/customers/${id}/discovers`);
	},
};
