import React from 'react';
import { Link } from 'react-router-dom';
import { notification } from 'antd';
import iconPhone from '../../../images/icons/phone.svg';
import iconNav from '../../../images/icons/navigate.svg';
import iconArrowRight from '../../../images/fillWhite/arrowRight.svg';

const GroupButton: React.FC<{
    salon: any;
}> = ({ salon }) => {
    const openDirect = (map: string) => {
        if (map) {
            window.open(map, '_blank');
            window.focus();
        } else {
            const config = {
                message: 'Thông Báo',
                description: 'Salon đang cập nhập tính năng chỉ đường!',
                duration: 3,
                className: 'error',
            };
            notification.error(config);
        }
    };

    return (
        salon && (
            <div className="flex flex-row justify-start text-[10px] gap-1 font-semibold pt-1">
                <div className="">
                    <a href={`tel:${salon.phone.replaceAll('.', '')}`}>
                        <div className="flex flex-row items-center justify-center gap-1 px-1 text-black bg-white rounded-sm">
                            <img className="w-[14px]" src={iconPhone} alt="" />
                            <span>HOTLINE</span>
                        </div>
                    </a>
                </div>
                <div
                    onClick={() => openDirect(salon.map)}
                    className="flex flex-row items-center justify-center gap-1 px-1 bg-white rounded-sm cursor-pointer"
                >
                    <img className="w-[14px]" src={iconNav} alt="" />
                    <span className="text-black ">Chỉ đường</span>
                </div>
                <Link
                    to={`/booking?salonId=${salon.id}&phone=0&step=0`}
                    className="rounded-sm flex flex-row bg-[#EF4E24] px-1 justify-center items-center gap-1"
                    title="Đặt lịch ngay"
                >
                    <span className="flex items-center justify-center font-semibold text-white">
                        Đặt lịch ngay
                    </span>
                    <img className="w-[14px]" src={iconArrowRight} alt="" />
                </Link>
            </div>
        )
    );
};

export default GroupButton;
