/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, createRef, FC } from 'react';
import { connect } from 'react-redux';
import iconZalo from 'images/icons/icon_zalo.svg';
import iconGlobal from 'images/icons/phone_global.svg';
import iconRaiting from 'images/icons/phone_raiting.svg';
import iconScroll from 'images/icons/scrollToTop.svg';
import iconMessenger from 'images/icons/icon_messenger.png';
import { PhoneFilled, CloseOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import API from 'repository';
import trackingConst from 'config/TrackingConst';
import $ from 'jquery';
import { getQuery } from '../func/Function';
import { setIsInApp } from '../../actions/common';
import MessengerCustomerChat from '../MessengerCustomerChat';
import './style.scss';
import FooterLayout from './FooterLayout';
import FooterLayoutV2 from './NewFooter';

const pathNoFooter = ['/booking', '/save-location', '/booking-at-home'];

const useOutside = (
    ref1: any,
    ref2: any,
    ref3: any,
    ref4: any,
    ref5: any,
    callBackFunction: Function,
) => {
    useEffect(() => {
        function handleClickOutside(event: any) {
            if (
                ref1.current &&
                !ref1.current.contains(event.target) &&
                ref2.current &&
                !ref2.current.contains(event.target) &&
                ref3.current &&
                !ref3.current.contains(event.target) &&
                ref4.current &&
                !ref4.current.contains(event.target) &&
                ref5.current &&
                !ref5.current.contains(event.target)
            ) {
                callBackFunction();
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref1, ref2, ref3, ref4, ref5]);
};

const FooterV3: FC<{ trackingData: any; setIsInApp: Function }> = ({
    trackingData,
    setIsInApp,
}) => {
    const appSources = ['customer_ios', 'customer_android', 'friendly_checkin'];
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
    const [queryParams, setQueryParams] = useState({ inApp: 0, source: '' });
    const location = useLocation();
    const [isInBooking, setIsInBooking] = useState(false);
    const [isShowMess, setIsShowMess] = useState(false);
    const [isToggles, setIsToggles] = useState(false);
    const refDiv = createRef<any>();
    const refDiv2 = createRef<any>();
    const refDiv3 = createRef<any>();
    const refDiv4 = createRef<any>();
    const refDiv5 = createRef<any>();

    async function postTracking(eventId: string) {
        try {
            const dataTracking = {
                pageId: trackingConst.Pages.COMMON_PAGE,
                screenId: trackingConst.Screens.COMMON_SCREEN,
                eventId,
                tokenKey: trackingData.token,
                source: trackingData.source,
                utmSource: trackingData.utmSource,
                data: {
                    phone: trackingData.phoneNumber || '',
                    customerId: trackingData.customerId || 0,
                },
            };
            await API.postTrackingDynamic(dataTracking);
        } catch (e) {
            console.log(e.message);
        }
    }
    const onHandleClickContact = () => {
        setIsToggles(!isToggles);
        const eventId = !isToggles
            ? trackingConst.Events.CLICK_SHOW_BUTTON_CONTACT
            : trackingConst.Events.CLICK_HIDE_BUTTON_CONTACT;
        postTracking(eventId);
    };

    useOutside(refDiv, refDiv2, refDiv3, refDiv4, refDiv5, () => {
        if (isToggles) {
            setIsToggles(false);
            postTracking(trackingConst.Events.CLICK_HIDE_BUTTON_CONTACT);
        }
    });

    useEffect(() => {
        if (pathNoFooter.includes(location.pathname)) {
            setIsInBooking(true);
        } else {
            setIsInBooking(false);
        }
    }, [location.pathname]);

    useEffect(() => {
        if (isShowMess) {
            postTracking(trackingConst.Events.CLICK_MESSENGER);
        }
    }, [isShowMess]);

    useEffect(() => {
        if (isToggles && isMobile) {
            $('body').css({
                overflow: 'hidden',
                height: '100%',
            });
        } else {
            $('body').css({
                overflow: 'auto',
                height: 'auto',
            });
        }
    }, [isToggles, isMobile]);

    useEffect(() => {
        setQueryParams(getQuery(window.location.search));
        // history.listen((location) => {
        //     setQueryParams(getQuery(location.search))
        // })
        setIsInApp(getQuery(window.location.search).inApp === '1');
    }, []);

    const isHideFooter =
        // isMobile &&
        queryParams.inApp ||
        appSources.includes(queryParams.source) ||
        (isMobile && isInBooking);

    return (
        <div>
            {!isHideFooter && (
                <div className="layout-footer">
                    <FooterLayoutV2 />
                    {isMobile ? null : (
                        <div>
                            <a
                                style={{ bottom: 165 }}
                                className="float-button"
                                onClick={() => {
                                    window.scrollTo({
                                        top: 0,
                                        behavior: 'smooth',
                                    });
                                }}
                            >
                                <img src={iconScroll} alt="close" />
                            </a>
                            <a
                                href="tel:1900299993"
                                className="float-button"
                                onClick={() => {
                                    postTracking(
                                        trackingConst.Events.CLICK_HOTLINE,
                                    );
                                }}
                            >
                                <img src={iconGlobal} alt="close" />
                            </a>
                        </div>
                    )}
                    {isMobile && location.pathname !== '/vong-quay-may-man' && (
                        <>
                            <a
                                style={{ bottom: 135 }}
                                className="float-button"
                                onClick={() => {
                                    window.scrollTo({
                                        top: 0,
                                        behavior: 'smooth',
                                    });
                                }}
                            >
                                <img src={iconScroll} alt="close" />
                            </a>
                            <div
                                style={{
                                    backgroundColor: isToggles
                                        ? 'rgba(0,0,0,0.8)'
                                        : 'transparent',
                                    width: isToggles ? '100%' : 'auto',
                                    height: isToggles ? '100%' : 'auto',
                                }}
                                className={`show-btn-wrapper ${
                                    isToggles ? 'toggle' : ''
                                }`}
                            >
                                <div
                                    onClick={() => onHandleClickContact()}
                                    className="zalo-chat-custom"
                                    style={{ bottom: isToggles ? '500px' : '' }}
                                    aria-hidden="true"
                                    ref={refDiv}
                                >
                                    {isToggles ? (
                                        <CloseOutlined
                                            style={{ color: '#fff' }}
                                            className="icon-close"
                                        />
                                    ) : (
                                        <img src={iconGlobal} alt="close" />
                                    )}
                                </div>
                                <div
                                    className={
                                        isToggles
                                            ? 'zalo-chat-custom-active'
                                            : 'zalo-chat-custom-no-active'
                                    }
                                >
                                    {/* <div
                                        className="zalo-chat-custom2"
                                        ref={refDiv2}
                                    >
                                        <div>Chat trên Zalo</div>
                                        <a
                                            href="//zalo.me/3566869536890280369"
                                            target="_blank"
                                            rel="nofollow noreferrer"
                                            onClick={() =>
                                                postTracking(
                                                    trackingConst.Events
                                                        .CLICK_ZALO,
                                                )
                                            }
                                        >
                                            <img
                                                src={iconZalo}
                                                alt="zalo-icon"
                                            />
                                        </a>
                                    </div> */}
                                    <div
                                        className="float-button2"
                                        ref={refDiv3}
                                    >
                                        <div>Chat trên Facebook</div>
                                        <a
                                            href="http://m.me/265069140024106"
                                            target="_blank"
                                            rel="nofollow noreferrer"
                                            onClick={() =>
                                                postTracking(
                                                    trackingConst.Events
                                                        .CLICK_MESSENGER,
                                                )
                                            }
                                        >
                                            <img
                                                src={iconMessenger}
                                                alt="messenger-icon"
                                            />
                                        </a>
                                    </div>
                                    <div
                                        className="float-button3"
                                        ref={refDiv4}
                                    >
                                        <div>Gọi hotline</div>
                                        <a
                                            href="tel:1900299993"
                                            className="btn-main"
                                            onClick={() => {
                                                postTracking(
                                                    trackingConst.Events
                                                        .CLICK_HOTLINE,
                                                );
                                            }}
                                        >
                                            <PhoneFilled
                                                rotate={90}
                                                className="text-white icon"
                                            />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            )}
            {/* {isMobile || isHideFooter ? null : (
                <MessengerCustomerChat
                    pageId="1656574444558351"
                    shouldShowDialog={false}
                    showBadge={!isHideFooter}
                    greetingDialogDisplay="hide"
                    onCustomerChatDialogShow={() => setIsShowMess(true)}
                    onCustomerChatDialogHide={() => {
                        setIsShowMess(false);
                    }}
                />
            )} */}
        </div>
    );
};

const mapStateToProps = (state: any) => {
    const { trackingData } = state;
    return {
        trackingData,
    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        setIsInApp: (value: boolean) => dispatch(setIsInApp(value)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FooterV3);
